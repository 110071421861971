import { useContext } from 'react';
import './OverflowWrapper.scss';

import { MouseContext } from '../../../context/MouseContext';

interface IProps {
  customStyle?: string;
  height?: string;
  width?: string;
  children: React.ReactNode;
  overflow?: 'visible' | 'hidden' | 'auto' | 'scroll';
  marginTop?: string;
  position?: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed';
  zIndex?: number;
  overflowX?: 'scroll';
}

function OverflowWrapper(props: IProps): JSX.Element {
  const {
    height,
    children,
    width,
    customStyle,
    overflow,
    marginTop,
    position,
    zIndex,
    overflowX,
  } = props;
  const classNames = ['overflow-wrapper'];
  const { cursorChangeHandler } = useContext(MouseContext);
  if (customStyle) classNames.push(customStyle);

  return (
    <div
      className={classNames.join(' ')}
      style={{
        overflow: overflow || 'hidden',
        height,
        width: width || '100%',
        marginTop,
        position: position || 'relative',
        zIndex,
        overflowX,
      }}
      onMouseEnter={() => cursorChangeHandler('')}
      onMouseLeave={() => cursorChangeHandler('')}
    >
      {children}
    </div>
  );
}

export default OverflowWrapper;
