import './CustomCursor.scss';

import { useContext, useEffect } from 'react';
import { gsap } from 'gsap';
import useMousePosition from '../../../hooks/useMousePosition';
import { MouseContext } from '../../../context/MouseContext';

const Cursor = (): any => {
  const { cursorType, cursorText } = useContext(MouseContext);
  const { x, y } = useMousePosition();

  useEffect(() => {
    if (cursorType === 'loading') {
      const tl = gsap.timeline();

      tl.to('.custom-cursor__text', {
        duration: 0.8,
        y: 0,
        ease: 'power3.out',
        opacity: 1,
      });
    }
  }, [cursorType]);
  return (
    <>
      <div
        className={`custom-cursor ${cursorType}`}
        style={{
          left: `${x}px`,
          top: `${y}px`,
        }}
      />
      <p
        className={`custom-cursor__text ${cursorType}`}
        style={{ left: `${x}px`, top: `${y}px` }}
      >
        {cursorText}
      </p>
    </>
  );
};
export default Cursor;
