import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './language/i18n';
import MouseContextProvider from './context/MouseContext';

ReactGA.initialize('UA-164818209-1');

ReactDOM.render(
  <React.StrictMode>
    <MouseContextProvider>
      <Router>
        <App />
      </Router>
    </MouseContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
