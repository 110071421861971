import './PhotosPage.scss';
import { useState, useContext, useLayoutEffect } from 'react';
import { photos } from '../../utils/photos-utils';
import { Menu, ImageCard } from '../../components/Complex';
import {
  preventsFlashing,
  handleRemovePage,
} from '../../utils/animation-utils';
import { areImagesLoaded } from '../../utils/utils';
import { MouseContext } from '../../context/MouseContext';
// import useMediaQuery from '../../hooks/useMediaQuery';
import { OverflowWrapper } from '../../components/Basic';

function PhotosPage(): JSX.Element {
  // const isPageWide = useMediaQuery('(min-width: 600px)');
  const [imgsLoaded, setImgsLoaded] = useState(false);
  const { cursorChangeHandler } = useContext(MouseContext);

  useLayoutEffect(() => {
    if (imgsLoaded) {
      cursorChangeHandler('');
    } else {
      cursorChangeHandler('loading', 'Loading');
      areImagesLoaded(
        photos.map((project) => project.photo),
        setImgsLoaded,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgsLoaded]);

  useLayoutEffect(() => {
    if (imgsLoaded) {
      preventsFlashing('.photos-page__content');
    }
  }, [imgsLoaded]);

  return (
    <div className="photos-page__content">
      <Menu
        type="top"
        zIndex={100}
        position="fixed"
        handleRemovePage={() =>
          handleRemovePage(['.photos-page__container'], 0.1, 0.1)
        }
      />
      <div className="photos-page__container">
        {photos.map((e, i) => {
          return (
            <OverflowWrapper customStyle="photos-page__item">
              <ImageCard id={i} image={e.photo} isImageLoaded={imgsLoaded} />
            </OverflowWrapper>
          );
        })}
      </div>
    </div>
  );
}

export default PhotosPage;
