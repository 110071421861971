import './PlaygroundPage.scss';
import { useState, useContext, useLayoutEffect } from 'react';
import { ImageCard, Menu } from '../../components/Complex';
import {
  preventsFlashing,
  showTextAnimation,
  handleRemovePage,
} from '../../utils/animation-utils';
import { areImagesLoaded } from '../../utils/utils';
import { playgroundProjects } from '../../utils/playground-utils';
import { MouseContext } from '../../context/MouseContext';
// import useMediaQuery from '../../hooks/useMediaQuery';
import { OverflowWrapper } from '../../components/Basic';

function PlaygroundPage(): JSX.Element {
  // const isPageWide = useMediaQuery('(min-width: 600px)');
  const [imgsLoaded, setImgsLoaded] = useState(false);
  const { cursorChangeHandler } = useContext(MouseContext);

  useLayoutEffect(() => {
    if (imgsLoaded) {
      cursorChangeHandler('');
    } else {
      cursorChangeHandler('loading', 'Loading');
      areImagesLoaded(
        playgroundProjects.map((project) => project.cover),
        setImgsLoaded,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgsLoaded]);

  useLayoutEffect(() => {
    if (imgsLoaded) {
      preventsFlashing('.playground-page__content');
      showTextAnimation(['.image-card__container'], 0.3, 2000);
      // onScrollImagesAnimation(
      //   '.image-card__container',
      //   '.playground-page__content',
      // );
    }
  }, [imgsLoaded]);

  return (
    <div className="playground-page__content">
      <Menu
        type="top"
        zIndex={100}
        position="fixed"
        handleRemovePage={() =>
          handleRemovePage(['.playground-page__images--container'], 0.1, 0.1)
        }
      />
      <div className="playground-page__images--container">
        {playgroundProjects.map((project) => {
          return (
            <OverflowWrapper
              customStyle={`image-card__container ${project.sizeClass}`}
            >
              <ImageCard
                id={project.id}
                key={project.id}
                image={project.cover}
                gif={project.gif}
                // onHoverImage={project.onHover}
                // category={project.category}
                // title={project.title}
                // year={project.year}
                isImageLoaded={imgsLoaded}
              />
            </OverflowWrapper>
          );
        })}
      </div>
    </div>
  );
}

export default PlaygroundPage;
